export interface EventData {
    readonly name: string;
    value: any;
}

export interface HeaderMessage {
    message: string;
    hasError: boolean;
}

export class EventLogout implements EventData {
    static EVENT_LOGOUT: string = 'logout';
    readonly name:  string = EventLogout.EVENT_LOGOUT;
    value: string;

    constructor( value: string ) { this.value = value; }
}

export class EventHeaderMessage implements EventData {
    static EVENT_HEADER_MSG: string = 'header-message';
    readonly name: string = EventHeaderMessage.EVENT_HEADER_MSG;
    value: HeaderMessage;

    constructor( value: HeaderMessage ) { this.value = value; }
}

