import { Injectable } from '@angular/core';
import { Subject, filter, map } from 'rxjs';
import { EventData } from '../shared/interfaces/events';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {

  subject = new Subject<EventData>();

  constructor() { }

  emit(event: EventData) {
    this.subject.next(event);
  }

  on(eventName: string, action: any) {
    return this.subject.pipe(
      filter((e: EventData) => e.name === eventName),
      map((e: EventData) => e)
    ).subscribe(action);
  }
}
